<template>
  <div>
    <v-progress-linear indeterminate class="my-1" v-if="loading" />
    <v-layout class="record-player white py-2" justify-center>
      <v-spacer></v-spacer>
      <vuetify-audio
        :canPlay="stopLoading"
        autoPlay
        flat
        :file="file"
        color="success"
        downloadable
      ></vuetify-audio>
      <v-spacer></v-spacer>
      <v-btn class="mx-2" color="error" fab x-small @click="$emit('closed')"
        ><v-icon>mdi-close</v-icon></v-btn
      >
    </v-layout>
  </div>
</template>

<script>
import { notifyError } from "@/components/Notification";
import Vue from "vue";
import colors from "vuetify/lib/util/colors";

// import Cursor from "wavesurfer.js/dist/plugin/wavesurfer.cursor";

export default Vue.extend({
  name: "record-player",
  components: {
    VuetifyAudio: () => import("vuetify-audio"),
  },
  props: {
    file: String,
  },
  data() {
    return {
      loading: false,
    };
  },
  watch: {
    file(val) {
      if (val != null && val != undefined && val != "") {
        this.initLoad();
      }
    },
  },
  mounted() {
    this.initLoad();
  },
  methods: {
    initLoad() {
      this.loading = true;
    },
    stopLoading() {
      this.loading = false;
    },
  },
});
</script>

<style lang="scss">
.btn-play {
  box-shadow: 0 0 0 14px #e0e0e0;
}
</style>

<i18n>
{
  "en": {
    "loadError": "Occurred while the file was loading"
  },
  "es": {
    "loadError": "Ocurrió mientras se cargaba el archivo"
  }
}
</i18n>

<style lang="scss">
.record-player {
}
</style>
